import { defineStore } from "pinia";
import jwtInterceptor from "@/helpers/http";
import useMainStore from '@/store/main.store';
const useUsersStore = defineStore({
    id: 'usersStore',
    state: () => ({
        filteredUsers: Array(),
        totalRecords: 0,
        users: Array(),
        administrators: Array(),
        nonSubscribers: Array(),
        userById: {},
        subUserById: {},
        self: {},
        currentEditIndex: 0,
        sortColumn: ''
    }),
    getters: {
        allUsers: (state) => state.users.concat(state.administrators),
    },
    actions: {
        async fetchUsersForFilter(filter) {
            const mainStore = useMainStore();
            mainStore.busy = true;
            try {
                const queryParams = filter ? Object.keys(filter).map(k => encodeURIComponent(k) + '=' + encodeURIComponent(filter[k])).join('&') : '';
                await jwtInterceptor.get("api/user/filter?" + queryParams)
                    .then((response) => {
                    this.filteredUsers = response.data.users;
                    this.totalRecords = response.data.totalRecords;
                })
                    .catch((e) => {
                    throw "Unable to fetch users for filter. Msg: " + e.response.data;
                });
            }
            finally {
                mainStore.busy = false;
            }
        },
        async fetchAllUsers() {
            const mainStore = useMainStore();
            mainStore.busy = true;
            try {
                const role = "User";
                await jwtInterceptor.get("api/user/role/" + role)
                    .then((response) => {
                    this.users = response.data;
                })
                    .catch((e) => {
                    throw "Unable to fetch users. Msg: " + e.response.data;
                });
            }
            finally {
                mainStore.busy = false;
            }
        },
        async fetchAllAdministrators() {
            const mainStore = useMainStore();
            mainStore.busy = true;
            try {
                const role = "administrator";
                await jwtInterceptor.get("api/user/role/" + role)
                    .then((response) => {
                    this.administrators = response.data;
                })
                    .catch((e) => {
                    throw "Unable to fetch administrators. Msg: " + e.response.data;
                });
            }
            finally {
                mainStore.busy = false;
            }
        },
        async fetchNonSubscribers() {
            const mainStore = useMainStore();
            mainStore.busy = true;
            try {
                await jwtInterceptor.get("api/user/notsubscribed/")
                    .then((response) => {
                    this.administrators = response.data;
                })
                    .catch((e) => {
                    throw "Unable to fetch nonsubscribers. Msg: " + e.response.data;
                });
            }
            finally {
                mainStore.busy = false;
            }
        },
        async fetchSelf() {
            const mainStore = useMainStore();
            mainStore.busy = true;
            try {
                await jwtInterceptor.get("api/user/me")
                    .then((response) => {
                    this.self = response.data;
                })
                    .catch((e) => {
                });
            }
            finally {
                mainStore.busy = false;
            }
        },
        async fetch(id) {
            const mainStore = useMainStore();
            mainStore.busy = true;
            try {
                await jwtInterceptor.get("api/user/" + id)
                    .then((response) => {
                    this.userById = response.data;
                })
                    .catch((e) => {
                    throw "Unable to fetch user. Msg: " + e.response.data;
                });
            }
            finally {
                mainStore.busy = false;
            }
        },
        async fetchSubUser(id) {
            const mainStore = useMainStore();
            mainStore.busy = true;
            try {
                await jwtInterceptor.get("api/user/sub/" + id)
                    .then((response) => {
                    this.subUserById = response.data;
                })
                    .catch((e) => {
                    throw "Unable to fetch user. Msg: " + e.response.data;
                });
            }
            finally {
                mainStore.busy = false;
            }
        },
        async saveUser(mdl) {
            const mainStore = useMainStore();
            mainStore.busy = true;
            try {
                await jwtInterceptor.post("api/user", mdl)
                    .then((response) => {
                    return true;
                })
                    .catch((e) => {
                    throw 'An error occurred while trying to save the user. Msg: ' + e.response.data;
                });
            }
            finally {
                mainStore.busy = false;
            }
        },
        async updateUser(mdl) {
            const mainStore = useMainStore();
            mainStore.busy = true;
            try {
                await jwtInterceptor.put("api/user", mdl)
                    .then((response) => {
                    return true;
                })
                    .catch((e) => {
                    throw 'An error occurred while trying to update the user. Msg: ' + e.response?.data;
                });
            }
            finally {
                mainStore.busy = false;
            }
        },
        async updateSelfUserInfo(mdl) {
            const mainStore = useMainStore();
            mainStore.busy = true;
            try {
                await jwtInterceptor.put("api/user/my/info", mdl)
                    .then((response) => {
                    return true;
                })
                    .catch((e) => {
                    throw 'An error occurred while trying to update the user. Msg: ' + e.response.data;
                });
            }
            finally {
                mainStore.busy = false;
            }
        },
        async updateSubUserInfo(mdl) {
            const mainStore = useMainStore();
            mainStore.busy = true;
            try {
                await jwtInterceptor.put("api/user/sub/info", mdl)
                    .then((response) => {
                    return true;
                })
                    .catch((e) => {
                    throw 'An error occurred while trying to update the user. Msg: ' + e.response?.data;
                });
            }
            finally {
                mainStore.busy = false;
            }
        },
        async activateSubUser(subuserId) {
            const mainStore = useMainStore();
            mainStore.busy = true;
            try {
                await jwtInterceptor.put("api/user/sub/activate/" + subuserId)
                    .then((response) => {
                    return true;
                })
                    .catch((e) => {
                    throw 'An error occurred while trying to activate the subuser. Msg: ' + e.response.data;
                });
            }
            finally {
                mainStore.busy = false;
            }
        },
        async deactivateSubUser(subuserId) {
            const mainStore = useMainStore();
            mainStore.busy = true;
            try {
                await jwtInterceptor.put("api/user/sub/deactivate/" + subuserId)
                    .then((response) => {
                    return true;
                })
                    .catch((e) => {
                    throw 'An error occurred while trying to deactivate the subuser. Msg: ' + e.response.data;
                });
            }
            finally {
                mainStore.busy = false;
            }
        },
        async activateUser(subuserId) {
            const mainStore = useMainStore();
            mainStore.busy = true;
            try {
                await jwtInterceptor.put("api/user/activate/" + subuserId)
                    .then((response) => {
                    return true;
                })
                    .catch((e) => {
                    throw 'An error occurred while trying to activate the user. Msg: ' + e.response.data;
                });
            }
            finally {
                mainStore.busy = false;
            }
        },
        async deactivateUser(subuserId) {
            const mainStore = useMainStore();
            mainStore.busy = true;
            try {
                await jwtInterceptor.put("api/user/deactivate/" + subuserId)
                    .then((response) => {
                    return true;
                })
                    .catch((e) => {
                    throw 'An error occurred while trying to deactivate the user. Msg: ' + e.response.data;
                });
            }
            finally {
                mainStore.busy = false;
            }
        },
    }
});
export default useUsersStore;
