<template>
    <div>
        <component :is="layout">
            <slot />
        </component>
    </div>
</template>

<script>
    import useAuthStore from "@/store/auth.store";
    import AppLayoutPublic from '@/layouts/AppLayoutPublic.vue'
    import AppLayoutAuthorized from '@/layouts/AppLayoutAuthorized.vue'
    import AppLayoutError from '@/layouts/AppLayoutError.vue'
    import { shallowRef, watch } from 'vue'
    import { useRoute } from 'vue-router'

    export default {
        name: 'AppLayout',
        setup() {
            const route = useRoute();
            const authStore = useAuthStore();

            let layout = shallowRef(AppLayoutPublic);
            if (route.meta.layout == "AppLayoutError") {
                layout = shallowRef(AppLayoutError);
            }
            else if (route.meta.layout == "AppLayoutPublic") {
                layout = shallowRef(AppLayoutPublic);
            }
            else if (route.meta.layout == "AppLayoutAuthorized" && authStore.isActivelyLoggedIn) {
                layout = shallowRef(AppLayoutAuthorized);
            }
            
            watch(
                () => route.meta,
                async meta => {
                    try {
                        const component = await require(`@/layouts/${meta.layout}.vue`)
                        layout.value = component?.default || AppLayoutPublic
                    } catch (e) {
                        layout.value = AppLayoutPublic
                    }
                },
            )
            return { layout }
        }
    }
</script>