import { defineStore } from "pinia";
import jwtInterceptor from "@/helpers/http";
import useMainStore from '@/store/main.store';
import router from "@/router";
const useImportRecordsStore = defineStore({
    id: 'importRecordsStore',
    state: () => ({
        importRecords: Array(),
        recordToImport: {},
        importPreview: {},
        importHistory: {},
        reviewRecords: Array(),
        currentEditIndex: 0,
        sortColumn: '',
    }),
    actions: {
        async fetchAllImportRecords() {
            const mainStore = useMainStore();
            mainStore.busy = true;
            try {
                await jwtInterceptor.get("api/imports")
                    .then((response) => {
                    this.importRecords = response.data;
                })
                    .catch((e) => {
                    mainStore.setErrorMsg("Unable to fetch imports. Msg: " + e.response.data);
                });
            }
            catch {
                mainStore.setErrorMsg("Unable to fetch imports.");
            }
            finally {
                mainStore.busy = false;
            }
        },
        async fetchAllImportRecordsForUsersCustomer() {
            const mainStore = useMainStore();
            mainStore.busy = true;
            try {
                await jwtInterceptor.get("api/imports/history")
                    .then((response) => {
                    this.importHistory = response.data;
                })
                    .catch((e) => {
                    mainStore.setErrorMsg("Unable to fetch import history. Msg: " + e.response.data);
                });
            }
            catch {
                mainStore.setErrorMsg("Unable to fetch import history.");
            }
            finally {
                mainStore.busy = false;
            }
        },
        async fetchImportRecordsForReview(id) {
            const mainStore = useMainStore();
            mainStore.busy = true;
            try {
                await jwtInterceptor.get("api/imports/review/" + id)
                    .then((response) => {
                    this.reviewRecords = response.data;
                })
                    .catch((e) => {
                    mainStore.setErrorMsg("Unable to fetch import details for review. Msg: " + e.response.data);
                    router.push('/dashboard');
                });
            }
            catch {
                mainStore.setErrorMsg("Unable to fetch import details for review.");
            }
            finally {
                mainStore.busy = false;
            }
        },
        async previewImportRecord(mdl) {
            const mainStore = useMainStore();
            mainStore.busy = true;
            try {
                const config = {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                    }
                };
                await jwtInterceptor.post("api/imports/preview", mdl, config)
                    .then((response) => {
                    this.recordToImport = mdl;
                    this.importPreview = response.data;
                    this.recordToImport.fileTotal = this.importPreview.fileTotal;
                    return true;
                })
                    .catch((e) => {
                    throw 'An error occurred while trying to preview the import, please try again. Msg: ' + e.response.data;
                });
            }
            catch {
                throw 'An error occurred while trying to preview the import, please try again.';
            }
            finally {
                mainStore.busy = false;
            }
        },
        async importRecord(mdl) {
            const mainStore = useMainStore();
            mainStore.busy = true;
            try {
                const config = {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                    }
                };
                await jwtInterceptor.post("api/imports/import", mdl, config)
                    .then((response) => {
                    return true;
                })
                    .catch((e) => {
                    mainStore.setErrorMsg('An error occurred while trying to import the file, please try again. Msg: ' + e.response.data);
                    router.push('/dashboard');
                });
            }
            catch {
                throw 'An error occurred while trying to import the file, please try again.';
            }
            finally {
                mainStore.busy = false;
            }
        },
        async approveRecord(id) {
            const mainStore = useMainStore();
            mainStore.busy = true;
            try {
                await jwtInterceptor.post("api/imports/approve/" + id)
                    .then((response) => {
                    const approvedRecord = this.reviewRecords.find((e) => e.id == id);
                    Object.assign(approvedRecord, response.data);
                    return true;
                })
                    .catch((e) => {
                    mainStore.setErrorMsg('An error occurred while trying to approve the record, please try again. Msg: ' + e.response.data);
                    router.push('/dashboard');
                });
            }
            catch {
                throw 'An error occurred while trying to approve the record, please try again.';
            }
            finally {
                mainStore.busy = false;
            }
        },
        async ignoreRecord(id) {
            const mainStore = useMainStore();
            mainStore.busy = true;
            try {
                await jwtInterceptor.post("api/imports/ignore/" + id)
                    .then((response) => {
                    const approvedRecord = this.reviewRecords.find((e) => e.id == id);
                    Object.assign(approvedRecord, response.data);
                    return true;
                })
                    .catch((e) => {
                    mainStore.setErrorMsg('An error occurred while trying to ignore the record, please try again. Msg: ' + e.response.data);
                    router.push('/dashboard');
                });
            }
            catch {
                throw 'An error occurred while trying to ignore the record, please try again.';
            }
            finally {
                mainStore.busy = false;
            }
        },
        async removeRecordApproval(id) {
            const mainStore = useMainStore();
            mainStore.busy = true;
            try {
                await jwtInterceptor.post("api/imports/unapprove/" + id)
                    .then((response) => {
                    const approvedRecord = this.reviewRecords.find((e) => e.id == id);
                    Object.assign(approvedRecord, response.data);
                    return true;
                })
                    .catch((e) => {
                    mainStore.setErrorMsg('An error occurred while trying to remove approval, please try again. Msg: ' + e.response.data);
                    router.push('/dashboard');
                });
            }
            catch {
                throw 'An error occurred while trying to remove approval, please try again.';
            }
            finally {
                mainStore.busy = false;
            }
        },
        async addNewPayee(id) {
            const mainStore = useMainStore();
            mainStore.busy = true;
            try {
                await jwtInterceptor.post("api/imports/add-payee/" + id)
                    .then((response) => {
                    const approvedRecord = this.reviewRecords.find((e) => e.importedRecordDetailId == id);
                    Object.assign(approvedRecord, response.data);
                    return true;
                })
                    .catch((e) => {
                    mainStore.setErrorMsg('An error occurred while trying to add new payee, please try again. Msg: ' + e.response.data);
                    router.push('/dashboard');
                });
            }
            catch {
                throw 'An error occurred while trying to add new payee, please try again.';
            }
            finally {
                mainStore.busy = false;
            }
        },
        async ignoreNewPayee(id) {
            const mainStore = useMainStore();
            mainStore.busy = true;
            try {
                await jwtInterceptor.post("api/imports/ignore-payee/" + id)
                    .then((response) => {
                    const approvedRecord = this.reviewRecords.find((e) => e.importedRecordDetailId == id);
                    Object.assign(approvedRecord, response.data);
                    return true;
                })
                    .catch((e) => {
                    mainStore.setErrorMsg('An error occurred while trying to ignore new payee, please try again. Msg: ' + e.response.data);
                    router.push('/dashboard');
                });
            }
            catch {
                throw 'An error occurred while trying to ignore new payee, please try again.';
            }
            finally {
                mainStore.busy = false;
            }
        },
        async flagPayee(id) {
            const mainStore = useMainStore();
            mainStore.busy = true;
            try {
                await jwtInterceptor.post("api/imports/flag-payee/" + id)
                    .then((response) => {
                    const approvedRecord = this.reviewRecords.find((e) => e.importedRecordDetailId == id);
                    Object.assign(approvedRecord, response.data);
                    return true;
                })
                    .catch((e) => {
                    mainStore.setErrorMsg('An error occurred while trying to flag the payee, please try again. Msg: ' + e.response.data);
                    router.push('/dashboard');
                });
            }
            catch {
                throw 'An error occurred while trying to flag the payee, please try again.';
            }
            finally {
                mainStore.busy = false;
            }
        },
        async removePayeeFlag(id) {
            const mainStore = useMainStore();
            mainStore.busy = true;
            try {
                await jwtInterceptor.post("api/imports/unflag-payee/" + id)
                    .then((response) => {
                    const approvedRecord = this.reviewRecords.find((e) => e.importedRecordDetailId == id);
                    Object.assign(approvedRecord, response.data);
                    return true;
                })
                    .catch((e) => {
                    mainStore.setErrorMsg('An error occurred while trying to remove the payee flag, please try again. Msg: ' + e.response.data);
                    router.push('/dashboard');
                });
            }
            catch {
                throw 'An error occurred while trying to remove the payee flag, please try again.';
            }
            finally {
                mainStore.busy = false;
            }
        },
    }
});
export default useImportRecordsStore;
