import { RefreshToken } from '@/helpers/http';
import { isTokenExpired } from '@/helpers/jwtHelper';
import useAuthStore from '@/store/auth.store';
import useMainStore from '@/store/main.store';
export function registerNavigate(router) {
    router.beforeEach(async (to, from, next) => {
        const authStore = useAuthStore();
        const mainStore = useMainStore();
        const isNotExpiredAndHasValue = (authStore.token && !isTokenExpired(authStore.token));
        // Initial Load
        if (mainStore.hasCreated == false) {
            if (to.path == '/')
                return next();
            else if (to.meta.requiresAuth == false)
                return next();
            else if (to.path != '/' && isNotExpiredAndHasValue) // allow the user to navigate to any path if they have a token that isn't expired.  This will allow them to refresh the path without getting booted back to the home screen.
                return next();
        }
        // Regular nav (non initial load)
        mainStore.clearMessages(); // clear out any displayed messages
        if (!authStore.isActivelyLoggedIn && to.meta.requiresAuth) {
            const refreshSuccessful = await RefreshToken();
            if (refreshSuccessful) {
                if (!to.meta.requiresAuth) {
                    next({ path: "/dashboard" }); // They are logged in, send them to the dashboard
                }
                return next();
            }
            else {
                return next({ path: "/login" });
            }
        }
        else if (!authStore.isActivelyLoggedIn && !to.meta.requiresAuth) {
            return next();
        }
        else if (authStore.isActivelyLoggedIn && to.meta.requiresAuth) {
            return next();
        }
        else {
            //the token is valid, but they are trying to navigate to a public page
            // TODO review this, you may not want to allow them to navigate to public pages
            return next();
        }
    });
}
