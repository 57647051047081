import { createRouter, createWebHistory } from 'vue-router';
import { registerNavigate } from './registerNavigate';
const routes = [
    { path: "/", name: "Home", component: () => import('@/views/Home.vue'), meta: { requiresAuth: false, layout: "AppLayoutPublic" } },
    { path: "/login", name: "Login", component: () => import('@/views/Login.vue'), meta: { requiresAuth: false, layout: "AppLayoutPublic" } },
    { path: "/about", name: "About", component: () => import('@/views/About.vue'), meta: { requiresAuth: false, layout: "AppLayoutPublic" } },
    { path: "/contact", name: "Contact", component: () => import('@/views/Contact.vue'), meta: { requiresAuth: false, layout: "AppLayoutPublic" } },
    { path: "/register", name: "Register", component: () => import('@/views/Register.vue'), meta: { requiresAuth: false, layout: "AppLayoutPublic" } },
    { path: "/logout", name: "Logout", component: () => import('@/views/Logout.vue'), meta: { requiresAuth: true, layout: "AppLayoutPublic" } },
    { path: "/unauthorized", name: "Unauthorized", component: () => import('@/views/UnAuthorizedView.vue'), meta: { requiresAuth: false, layout: "AppLayoutPublic" } },
    { path: "/forgot-password", name: "ForgotPassword", component: () => import('@/views/ForgotPasswordView.vue'), meta: { requiresAuth: false, layout: "AppLayoutPublic" } },
    { path: "/reset-password", name: "ResetPassword", component: () => import('@/views/ResetPasswordView.vue'), meta: { requiresAuth: false, layout: "AppLayoutPublic" } },
    { path: "/account", name: "Account", component: () => import('@/views/account/PrimaryAccountDetailsView.vue'), meta: { requiresAuth: true, layout: "AppLayoutAuthorized" } },
    { path: "/subaccount", name: "SubAccount", component: () => import('@/views/account/SubUserAccountDetailsView.vue'), meta: { requiresAuth: true, layout: "AppLayoutAuthorized" } },
    { path: "/admin-account", name: "AdminAccount", component: () => import('@/views/account/AdminAccountDetailsView.vue'), meta: { requiresAuth: true, layout: "AppLayoutAuthorized" } },
    { path: "/subaccount/edit/:id", name: "SubUserEdit", component: () => import('@/views/account/SubUserEdit.vue'), meta: { requiresAuth: true, layout: "AppLayoutAuthorized" } },
    { path: "/dashboard", name: "Dashboard", component: () => import('@/views/authorized/Dashboard.vue'), meta: { requiresAuth: true, layout: "AppLayoutAuthorized" } },
    { path: "/analysis", name: "Analysis", component: () => import('@/views/authorized/analysis/ImportView.vue'), meta: { requiresAuth: true, layout: "AppLayoutAuthorized" } },
    { path: "/analysis-import-history", name: "ImportHistory", component: () => import('@/views/authorized/analysis/ImportHistoryView.vue'), meta: { requiresAuth: true, layout: "AppLayoutAuthorized" } },
    { path: "/import-details/:id", name: "ImportDetails", component: () => import('@/views/authorized/analysis/ImportDetailsView.vue'), meta: { requiresAuth: true, layout: "AppLayoutAuthorized" } },
    { path: "/company", name: "Company", component: () => import('@/views/authorized/company/CompanyView.vue'), meta: { requiresAuth: true, allowedRoles: ["Administrator"], layout: "AppLayoutAuthorized" } },
    { path: "/company/add-update-company", name: "CompanyAddEdit", component: () => import('@/views/authorized/company/UpdateAddCompany.vue'), meta: { requiresAuth: true, layout: "AppLayoutAuthorized" } },
    { path: "/payees/:id", name: "PayeeAddEdit", component: () => import('@/views/authorized/payee/PayeeManagement.vue'), meta: { requiresAuth: true, layout: "AppLayoutAuthorized" } },
    { path: "/customer/add-update-customers", name: "CustomersAddEdit", component: () => import('@/views/authorized/customer/UpdateAddCustomer.vue'), meta: { requiresAuth: true, layout: "AppLayoutAuthorized" } },
    { path: "/users", name: "Users", component: () => import('@/views/authorized/users/UsersView.vue'), meta: { requiresAuth: true, allowedRoles: ["Administrator"], layout: "AppLayoutAuthorized" } },
    { path: "/users/add-update-user", name: "UsersAddEdit", component: () => import('@/views/authorized/users/UpdateAddUser.vue'), meta: { requiresAuth: true, layout: "AppLayoutAuthorized" } },
    { path: "/admin/subscription", name: "AdminSubscriptionManagement", component: () => import('@/views/admin/AdminSubscriptionManagement.vue'), meta: { requiresAuth: true, layout: "AppLayoutAuthorized" } },
    { path: "/admin/subscription/edit/:id", name: "SubscriptionEdit", component: () => import('@/views/admin/SubscriptionEdit.vue'), meta: { requiresAuth: true, allowedRoles: ["Administrator"], layout: "AppLayoutAuthorized" } },
    { path: "/admin/user/edit/:id", name: "AdminUserEdit", component: () => import('@/views/admin/AdminUserEdit.vue'), meta: { requiresAuth: true, allowedRoles: ["Administrator"], layout: "AppLayoutAuthorized" } },
    { path: "/admin/subuser/edit/:id", name: "AdminSubUserEdit", component: () => import('@/views/admin/AdminSubUserEdit.vue'), meta: { requiresAuth: true, allowedRoles: ["Administrator"], layout: "AppLayoutAuthorized" } },
    { path: '/404', name: "404", component: () => import('@/views/errors/404.vue'), meta: { requiresAuth: false, layout: "AppLayoutError" } },
    { path: '/:pathMatch(.*)*', component: () => import('@/views/errors/404.vue'), meta: { requiresAuth: false, layout: "AppLayoutError" } },
];
const router = createRouter({
    routes,
    history: createWebHistory()
});
registerNavigate(router);
export default router;
