import { defineStore } from "pinia";
const useMainStore = defineStore({
    id: 'mainStore',
    state: () => ({
        errorMsg: '',
        infoMsg: '',
        busy: false,
        hasCreated: false,
        errorMsgKey: Math.random().toString(),
        infoMsgKey: Math.random().toString()
    }),
    getters: {
        isBusy: (state) => state.busy,
    },
    actions: {
        async clearMessages() {
            this.infoMsg = '';
            this.errorMsg = '';
            this.errorMsgKey = Math.random().toString();
            this.infoMsgKey = Math.random().toString();
        },
        async setInfoMsg(msg) {
            this.infoMsg = msg;
            this.infoMsgKey = Math.random().toString();
        },
        async setErrorMsg(msg) {
            this.errorMsg = msg;
            this.errorMsgKey = Math.random().toString();
        },
    }
});
export default useMainStore;
