import jwt_decode from "jwt-decode";
function getJsonObjFromToken(token) {
    const retObj = jwt_decode(token);
    return JSON.stringify(retObj);
}
export function determineRole(token) {
    if (!token)
        return "";
    const jsonPayload = getJsonObjFromToken(token);
    const jsonObj = JSON.parse(jsonPayload);
    return jsonObj["http://schemas.microsoft.com/ws/2008/06/identity/claims/role"];
}
export function isTokenExpired(token) {
    if (token) {
        const retObj = jwt_decode(token);
        if (retObj) {
            return Date.now() > (retObj.exp * 1000);
        }
        else {
            return true;
        }
    }
    else {
        return true;
    }
}
export function jwtDecrypt(token) {
    const jsonPayload = getJsonObjFromToken(token);
    return JSON.parse(jsonPayload);
}
