<script setup>
    import useMainStore from '@/store/main.store'
    const items = [
        { label: 'Home', to: '/' },
        // { label: 'About', to: '/about' },
        { label: 'Contact Us', to: '/contact' }
    ];

    const store = useMainStore();

</script>
<template>
    <div>
        <div class="min-h-screen flex flex-column surface-ground">
            <div id="top-nav-container" class="surface-overlay py-3 px-6 flex align-items-center justify-content-between relative lg:static" style="min-height: 40px;">
                <router-link class="px-2" to="/">
                    <img src="../assets/Logo-Hor-White.png" height="50" class="mr-0 lg:mr-6"/>
                </router-link>
                <div class="align-items-center flex-grow-1 lg:justify-content-end hidden lg:flex absolute lg:static w-full left-0 top-100 px-6 lg:px-0 z-3 shadow-2 lg:shadow-none">
                    <Menubar id="top-nav-menu" :model="items" class="border-none">
                        <template #item="{item}">
                            <router-link style="text-decoration: none" class="nav-link flex px-0 lg:px-5 py-3 align-items-center font-medium transition-colors transition-duration-150 p-ripple"
                                         :to="item.to">
                                {{item.label}}
                            </router-link>
                        </template>
                    </Menubar>
                    <LoginComponent />

                </div>
            </div>

            <Toast group="action" position="top-right" />
            <Message severity="error" :key="store.errorMsgKey" v-show="store.errorMsg != ''"><span v-html="store.errorMsg"></span></Message>
            <Message severity="info" :key="store.infoMsgKey" v-show="store.infoMsg != ''"><span v-html="store.infoMsg"></span></Message>
            <slot />
        </div>

        <div id="footer-container" class="bg-gray-900 px-2 py-2 md:px-3 lg:px-4">
            <div class="grid">
                <div class="col-12 md:col-3 flex flex-wrap justify-content-start md:mb-0 mb-1">
                    <img src="../assets/Logo-Vert-White.png" height="130" class="mr-0 lg:mr-6" />
                </div>
                <div class="col-12 md:col-3">
                    <div class="text-white text-lg flex flex-wrap mb-2 font-bold" style="max-width: 290px">Safe ACH LLC</div>
                    <div class="text-white mb-3 address-footer-container">
                        
                        <p><i class="pi pi-envelope border-round p-1 mr-2"></i>PO Box 351</p>
                        <p class="ml-5">Custer, SD 57730</p>
                    </div>
                    <div class="text-white mb-3"><i class="pi pi-phone border-round p-1 mr-2"></i>1 (877) 220-0072</div>
                    <div class="text-white mb-3"><i class="pi pi-inbox border-round p-1 mr-2"></i>dan@safeach.com</div>
                </div>
                <!-- <div class="col-12 md:col-3 text-gray-200">
                    <div class="text-white font-bold line-height-3 mb-3">Company</div>
                    <a class="line-height-3 block cursor-pointer mb-2">About Us</a>
                    <a class="line-height-3 block cursor-pointer mb-2">News</a>
                </div> -->
                <div class="col-12 md:col-3 text-gray-200">

                </div>
            </div>
        </div>
    </div>
</template>
<style lang="css" scoped>
    #top-nav-menu {
        padding: 0;
    }

    .nav-link {
        color: white;
        background-color: #183462 !important;
    }

    #top-nav-container, #footer-container {
        background-color: #183462 !important;
    }

    ::v-deep(.surface-border) {
        border: none;
    }

    ::v-deep(.p-menuitem) {
        border-radius: 10px !important;
    }

    ::v-deep(.p-menubar > * :hover) {
        background-color: #102343 !important;
    }

    ::v-deep(.p-menubar) {
        background-color: #183462;
    }

    ::v-deep(.p-menuitem :hover) {
        border-radius: 10px !important;
        background-color: ;
    }
</style>
