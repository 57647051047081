import axios from "axios";
import { defineStore } from "pinia";
import { isTokenExpired } from "@/helpers/jwtHelper";
import jwtInterceptor from "@/helpers/http";
import useMainStore from '@/store/main.store';
import { useLocalStorage } from "@vueuse/core";
import router from "@/router";
const http = axios.create({ baseURL: process.env.VUE_APP_APIBASEURL });
const storedToken = useLocalStorage('access_token', localStorage.getItem('access_token') ?? '');
const storedRefreshToken = useLocalStorage('refresh_token', localStorage.getItem('refresh_token') ?? '');
const useAuthStore = defineStore({
    id: 'authStore',
    state: () => ({
        token: storedToken,
        refresh_token: storedRefreshToken,
        user_name: '',
    }),
    getters: {
        isActivelyLoggedIn(state) {
            return !isTokenExpired(state.token);
        },
        hasLoggedIn(state) {
            if (state.token)
                return true;
            else
                return false;
        }
    },
    actions: {
        async login(email, password, rememberMe) {
            const mainStore = useMainStore();
            mainStore.busy = true;
            try {
                const mdl = { email, password, rememberMe };
                return await http.post("api/auth/login", mdl)
                    .then((response) => {
                    if (response.status === 200)
                        return true;
                    else
                        return false;
                });
            }
            catch (e) {
                if (e.response.status === 400) {
                    mainStore.setErrorMsg("Invalid email or password.");
                }
            }
            finally {
                mainStore.busy = false;
            }
            return false;
        },
        async verify(email, password, rememberMe, code) {
            const mainStore = useMainStore();
            mainStore.busy = true;
            try {
                const mdl = { code, email, password, rememberMe };
                await http.post("api/auth/verify", mdl)
                    .then((response) => {
                    const r = response.data;
                    this.token = r.token;
                    this.refresh_token = r.refreshToken;
                    return r;
                })
                    .catch((e) => {
                    const r = e.response.data;
                    if (r.success !== undefined && r.success === false) {
                        mainStore.setErrorMsg("Something went wrong, redirecting you...");
                        router.go(0);
                    }
                    else {
                        mainStore.setErrorMsg("Failed to verify.");
                    }
                });
            }
            finally {
                mainStore.busy = false;
            }
        },
        async register(email, firstName, lastName, phoneNumber, password, confirmPassword, organizationName, organizationEmail, customerId) {
            const mainStore = useMainStore();
            mainStore.busy = true;
            customerId = customerId ?? -1;
            try {
                const mdl = { email, firstName, lastName, phoneNumber, password, confirmPassword, organizationName, organizationEmail, customerId };
                await http.post("api/auth/register", mdl)
                    .then((response) => {
                    return true;
                })
                    .catch((e) => {
                    mainStore.setErrorMsg(e.response.data);
                    throw "Failed to register. " + e.response.data;
                });
            }
            finally {
                mainStore.busy = false;
            }
        },
        async forgotPassword(email) {
            const mainStore = useMainStore();
            mainStore.busy = true;
            try {
                const mdl = { email };
                await http.post("api/auth/forgot-password", mdl)
                    .then((response) => {
                    const r = response.data;
                    return;
                })
                    .catch((e) => {
                    throw "Unable to send you a forgotten password link.  Please try again later. " + e.response.data;
                });
            }
            finally {
                mainStore.busy = false;
            }
        },
        async resetPassword(email, password, confirmPassword, code) {
            const mainStore = useMainStore();
            mainStore.busy = true;
            try {
                const mdl = { email, password, confirmPassword, code };
                await http.post("api/auth/reset-password", mdl)
                    .then((response) => {
                    const r = response.data;
                    return;
                })
                    .catch((e) => {
                    throw "Failed to reset your your password. " + e.response.data;
                });
            }
            finally {
                mainStore.busy = false;
            }
        },
        async logout() {
            try {
                await jwtInterceptor.post("api/auth/revoke", {});
            }
            finally {
                this.token = '';
                localStorage.removeItem("access_token");
                localStorage.removeItem("refresh_token");
            }
        },
        async isAuthenticated() {
            const mainStore = useMainStore();
            try {
                mainStore.busy = true;
                await jwtInterceptor.get("api/auth/ping")
                    .then((response) => {
                    return "true";
                })
                    .catch((e) => {
                    throw "Sorry, we were unable to log you in. " + e.response.data;
                });
            }
            finally {
                mainStore.busy = false;
            }
        },
    },
});
export default useAuthStore;
