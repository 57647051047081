import axios from "axios";
import useAuthStore from '@/store/auth.store';
import useMainStore from '@/store/main.store';
import router from "@/router";
import { isTokenExpired } from '@/helpers/jwtHelper';
const jwtInterceptor = axios.create({ baseURL: process.env.VUE_APP_APIBASEURL });
let isRefreshing = false;
jwtInterceptor.interceptors.request.use(async (config) => {
    const store = useAuthStore();
    const controller = new AbortController();
    if (isRefreshing)
        return controller.abort();
    if (!store.token) {
        return config;
    }
    if (config.headers)
        config.headers.Authorization = `bearer ${store.token ?? ''}`;
    const isTokenInvalid = isTokenExpired(store.token);
    if (isTokenInvalid) {
        const result = await tryToRefreshAndHandle();
        if (result && result.response) {
            store.token = result.response.data?.token ?? '';
            store.refresh_token = result.response.data?.refreshToken ?? '';
            if (config.headers)
                config.headers.Authorization = `bearer ${result.response.data?.token ?? ''}`;
        }
        if (result.isSuccessful == false)
            controller.abort();
    }
    return config;
});
const tryToRefreshAndHandle = async () => {
    const store = useAuthStore();
    const mainStore = useMainStore();
    const payload = JSON.stringify({
        accessToken: store.token,
        refreshToken: store.refresh_token,
    });
    const axoisInstance = axios.create({ baseURL: process.env.VUE_APP_APIBASEURL });
    const response = await axoisInstance.post('api/auth/refresh', payload, {
        headers: {
            'Content-Type': 'application/json'
        }
    }).catch((e) => {
        // TODO catch specific codes here.  Do not sign people out if status code == 500, 401, 403
        //    only logout if its a 400.  Is that necessary?  
        mainStore.clearMessages();
        mainStore.setErrorMsg("Sorry your session has expired, you must login again.");
        store.token = "";
        store.refresh_token = "";
        router.push("/login");
        return {
            isSuccessful: false, response: null
        };
    });
    return {
        isSuccessful: true, response
    };
};
export async function RefreshToken() {
    try {
        isRefreshing = true;
        const store = useAuthStore();
        const payload = JSON.stringify({
            accessToken: store.token,
            refreshToken: store.refresh_token,
        });
        const axoisInstance = axios.create({ baseURL: process.env.VUE_APP_APIBASEURL });
        const response = await axoisInstance.post('api/auth/refresh', payload, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        return true;
    }
    catch {
        return false;
    }
    finally {
        isRefreshing = false;
    }
}
export default jwtInterceptor;
